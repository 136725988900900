
import { Options, Vue } from "vue-class-component";
import MyClassrooms from "@/components/classroomPackages/MyClassroomsBlock.vue";
import ClassroomBundles from "@/components/classroomPackages/ClassroomBundlesBlock.vue";
import AvailableBundles from "@/components/classroomPackages/AvailableBundlesBlock.vue";
import { classroom, bundle } from "@/utils/Types";
import { getUserInfo } from "@/utils/Functions";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  mounted() {
    this.updateQuestionButton();
  },
  data() {
    return {
      activeClassroom: null,
      activeBundles: null,
      wikiSubjects: [],
    };
  },
  components: {
    MyClassrooms,
    ClassroomBundles,
    AvailableBundles,
    QuestionButton,
  },
  methods: {
    updateClassroom(c: classroom) {
      this.activeClassroom = c;
    },
    updateAvailableBundles(b: bundle[]) {
      this.activeBundles = b;
    },
    async removeBundle(b: bundle) {
      await this.$refs.availableBundles.addAvailableBundle(b);
    },
    async addBundle(b: bundle) {
      await this.$refs.classroomBundles.addBundle(b);
    },
    updateQuestionButton() {
      this.wikiSubjects.push(
        {
          wikiButton: { name: this.$t("wiki.classign"), item: "classignPage" },
        }

        //here you can add more page references
      );
    },
    getUserInfo,
  },
  props: ["activeClassroom", "activeBundles"],
})
export default class ClassroomPackages extends Vue {}
