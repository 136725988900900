
import { Vue, Options } from "vue-class-component";
import { bundleAPI } from "@/utils/apiCalls";
import { bundle } from "@/utils/Types";

@Options({
  data() {
    return {
      available: [],
    };
  },
  watch: {
    //Update available bundles on update active bundles
    activeBundles: function (newVal) {
      this.updateAvailableBundles(newVal);
    },
  },
  methods: {
    /**
     * Use available bundle
     * @param b Bundle
     */
    useAvailableBundle(b: bundle) {
      const index = this.available.indexOf(b, 0);
      this.$emit("useBundle", b);
      this.available.splice(index, 1);
    },
    /**
     * Add available bundle
     * @param b Bundle
     */
    addAvailableBundle(b: bundle) {
      this.available.push(b);
    },
    /**
     * Update available bundles
     * @param bundles Bundle list
     */
    async updateAvailableBundles(bundles: bundle[]) {
      await bundleAPI.getAddableBundles().then((value) => {
        const set = new Set(bundles?.map((bundle: bundle) => bundle.bundleId));
        this.available = value?.filter(
          (bundle: bundle) => !set.has(bundle.bundleId)
        );
      });
    },
  },
  props: ["activeBundles"],
})
export default class AvailableBundlesBlock extends Vue {}
