
import { classroom } from "@/utils/Types";
import { Vue, Options } from "vue-class-component";
import { classAPI } from "@/utils/apiCalls";

@Options({
  data() {
    return {
      selected: null,
      classrooms: [],
      overlay: false,
    };
  },
  methods: {
    //Update classrooms on change
    async updateMyClassrooms() {
      await classAPI.getAllClassroomsOfSchool().then((value) => {
        this.classrooms = value;
      });
    },
    /**
     * Change selected classroom
     * @param c Classroom
     */
    changeSelectedClassroom(c: classroom) {
      this.selected = c;
      this.$emit("updateClassroom", c);
    },
  },
  mounted() {
    this.updateMyClassrooms();
  },
})
export default class MyClassroomsBlock extends Vue {}
