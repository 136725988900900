
import { Vue, Options } from "vue-class-component";
import { classAPI } from "@/utils/apiCalls";
import { classroom, bundle } from "@/utils/Types";

@Options({
  data() {
    return {
      bundles: [] as bundle[],
      toRemove: [] as bundle[],
      overlay: false,
      original: [] as bundle[],
    };
  },
  watch: {
    //Get bundles on active classroom change
    activeClassroom: function (newVal) {
      this.getBundles(newVal);
    },
  },
  methods: {
    /**
     * Get bundles
     * @param c Classroom
     */
    async getBundles(c: classroom) {
      await classAPI.getBundles(c.classroomId).then((value) => {
        this.bundles = value;
        this.$store.commit("setClassroomBundles", value);
        this.$emit("updateAvailableBundles", this.bundles);
      });
    },
    /**
     * Remove bundle
     * @param b Bundle
     */
    removeBundle(b: bundle) {
      const index = this.bundles.indexOf(b, 0);
      this.$emit("removeBundle", b);
      this.bundles.splice(index, 1);
      this.toRemove.push(b);
    },
    /**
     * Add bundle
     * @param b Bundle
     */
    addBundle(b: bundle) {
      if (this.toRemove.includes(b)) {
        this.toRemove.splice(this.toRemove.indexOf(b), 1);
      }
      this.bundles.push(b);
    },
    //Save classroom
    async saveClassroom() {
      for (let bu of this.toRemove) {
        await classAPI.removeBundle(
          this.$props.activeClassroom.classroomId,
          bu.bundleId
        );
      }
      for (let b of this.bundles) {
        await classAPI.addBundle(
          this.$props.activeClassroom.classroomId,
          b.bundleId
        );
      }
      this.overlay = true;
      this.original = this.bundles;
    },
  },
  props: ["activeClassroom"],
})
export default class ClassroomBundlesBlock extends Vue {}
